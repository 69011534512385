<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right;display: flex;">
       
            <el-input v-model="ivst_title" size="small" placeholder="标题关键字" style="max-width: 200px; margin-right: 10px;" />

            <el-button type="primary" plain size="small" icon="el-icon-search" @click="onSearch">搜索</el-button>

            <el-button type="primary" size="small" icon="el-icon-plus" @click="add">添加问卷</el-button>

            <!-- <el-button type="primary" @click="dialogMoreListVisible=true">多问卷列表</el-button>

  <el-button type="primary" @click="collectMore" v-if="multipleSelection.length>1">集合多个问卷</el-button> -->

      

      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>问卷调查</el-breadcrumb-item>
          <el-breadcrumb-item>问卷模板</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">


       <el-table v-tableFit :data="DataList" stripe border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="ivst_title" label="标题" min-width="180">
        </el-table-column>
        <el-table-column prop="create_user" label="创建人" min-width="180">
        </el-table-column>
        <el-table-column prop="cdate" label="适用对象" width="180">
          <template slot-scope="scope">

            <span style="display: inline-block;margin-right: 10px;"
              v-if="scope.row.target_user.indexOf('student')>=0">学生</span>
            <span style="display: inline-block;margin-right: 10px;"
              v-if="scope.row.target_user.indexOf('parent')>=0">家长</span>
            <span style="display: inline-block;margin-right: 10px;"
              v-if="scope.row.target_user.indexOf('enterpirse')>=0">企业</span>

          </template>
        </el-table-column>

        <el-table-column prop="cdate" label="创建时间" width="180">
        </el-table-column>

        <el-table-column prop="joincount" label="参与人数" width="100">
          <template slot-scope="scope">

            {{scope.row.joinnum}}{{scope.row.target_user.indexOf('enterpirse')>=0 ?'':'/'+scope.row.usernum}}

          </template>

        </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.deleted == 1">未启用</el-tag>
            <el-tag size="mini" v-if="scope.row.deleted == 0" style="color: #67c23a;">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">

            <el-button type="text" @click="updateDetail(scope.row)">编辑</el-button>
            <el-button type="text" @click="remove(scope.row.id)">删除</el-button>
            <el-button type="text" @click="exportXls(scope.row.id)">导出</el-button>
            <!--
          <el-button type="text" @click="showTj(scope.row)">统计</el-button> -->


          </template>
        </el-table-column>


      </el-table>
    </div>
    <!-- <Pagination :pagination="page"></Pagination> -->


    <el-dialog title="问卷参与列表" :visible.sync="dialogVisible" :close-on-click-modal="false" width="80%">

      <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
        <el-tab-pane label="参与列表" name="tab_list"></el-tab-pane>
        <el-tab-pane label="统计图表" name="tab_cart"></el-tab-pane>
      </el-tabs>
      <div v-show="activeName=='tab_list'">
         <el-table v-tableFit :data="JoinUserList" stripe border>
          <el-table-column type="index" label="编号" width="80">
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="120">
            <template slot-scope="scope">
              {{EditItem.noname?"匿名":scope.row.name}}
            </template>
          </el-table-column>

          <el-table-column prop="cdate" label="参与时间" width="160">
          </el-table-column>
          <el-table-column prop="score" label="得分" width="100">
          </el-table-column>
          <el-table-column prop="result" label="结果" min-width="180">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="viewDetial(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>


      <div v-if="activeName=='tab_cart'">
        <h5result :ivstid="EditItem.id"></h5result>
      </div>

    </el-dialog>



    <el-dialog title="多问卷设置" :visible.sync="dialogMoreVisible" :close-on-click-modal="false" width="800px">

      <el-form :model="moreInfo" style="margin-top: 10px;" label-width="100px">

        <el-form-item label="多问卷标题">

          <el-input type="text" v-model="moreInfo.title" placeholder="请输入多问卷标题"></el-input>

        </el-form-item>

        <el-form-item label="多问卷描述">

          <el-input type="textarea" v-model="moreInfo.ivst_desc" rows='5' placeholder="请输入多问卷描述"></el-input>

        </el-form-item>

        <el-form-item>

          <div style="text-align: right;">
            <el-button type="primary" @click="saveMore()">保存</el-button>
          </div>

        </el-form-item>
      </el-form>

    </el-dialog>


    <el-dialog title="问卷详情" :visible.sync="dialogVisible2" :close-on-click-modal="false" width="500px">
      <div v-if="dialogVisible2">
        <h5view :ivstid="subitem.ivst_id" :userid="subitem.uid"></h5view>
      </div>
    </el-dialog>


    <el-dialog title="多问卷列表" :visible.sync="dialogMoreListVisible" :close-on-click-modal="false" width="70%">
      <div v-if="dialogMoreListVisible">
        <ivstmore></ivstmore>
      </div>
    </el-dialog>


  </div>
</template>

<script>
// import Pagination from '../../com/pagination.vue'
import h5result from './h5result.vue'
import h5view from './h5.vue'
import ivstmore from './ivstmore.vue'
export default {
  components: {
    // Pagination,
    h5result,
    h5view,
    ivstmore
  },
  data() {
    return {
      EditItem: {},
      DataList: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogMoreVisible: false,
      dialogMoreListVisible: false,
      page: {
        current_page: 1,
        next_page: 1,
        pre_page: 1,
        total_page: 1,
        count: 0
      },
      JoinUserList: [],
      activeName: "tab_list",
      subitem: {

      },
      multipleSelection: [],
      moreInfo: {
        title: '',
        ivst_desc: '',

      },
      ivst_more_id: 0,
      ivst_title: '',
    };
  },
  watch: {
    $route() {
      //跳转到该页面后需要进行的操作
      this.getList(1);

    }
  },
  mounted() {
    this.getList(1);


  },
  methods: {
    onSearch() {
      console.log(111)
      this.getList(1);
    },
    getList(page) {
      this.$http.post("/api/ivst_list", {
        real_name: this.ivst_title,
        page: page
      }).then(
        res => {

          for (let item of res.data.data) {
            item.usernum = 0;
            let arr = item.target_user.split(",");
            for (let u of arr) {
              if (u && u != "0") {
                item.usernum++;
              }
            }
          }
          this.DataList = res.data.data;
          this.page = res.data.page

        })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    paginate(val) {
      this.getList(val)
    },
    updateDetail(e) {
      this.$router.push("/ivst_edit/" + e.id)
    },
    add() {
      this.$router.push("/ivst_edit/0")
    },
    showTj(e) {
      this.dialogVisible = true;
      this.EditItem = e



      this.$http.post("/admin/ivst_joinlist", {
        ivst_id: e.id
      }).then(
        res => {
          this.JoinUserList = res.data

        })





    },
    viewDetial(e) {
      this.dialogVisible2 = true;
      this.subitem = e

    },
    handleTabClick(e) {
      this.activeName = e.name
      console.log(e.name)
    },

    remove(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/ivst_delete", {
          id: id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          this.getList(this.page.current_page);
        })

      }).catch(() => {

      });
    },
    createQrcode(item) {
      this.$http.post("/admin/ivst_qrcode", {
        id: item.id
      }).then(res => {
        this.$message({
          type: 'success',
          message: '生成成功'
        });

        this.getList(this.page.current_page);
      })
    },
    cloneIvst(id) {
      this.$confirm('确认克隆该问卷?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/admin/ivst_clone", {
          id: id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '克隆成功'
          });

          this.getList(this.page.current_page);
        })

      }).catch(() => {

      });
    },
    collectMore() {
      this.dialogMoreVisible = true
    },
    saveMore() {
      this.moreInfo.ivst_ids = this.multipleSelection.map(e => { return e.id }).join(",")
      this.$http.post("/admin/ivst_more_create", this.moreInfo).then(res => {
        this.$message({
          type: 'success',
          message: '生成成功'
        });
        this.dialogMoreVisible = false
        this.dialogMoreListVisible = false

      })

    },
    exportXls(id) {
      this.$http.post('/api/export_ivst',{id:id}).then(res => {
        if (res.data.url) {
          window.open(res.data.url,'_blank')
        }
      })
    },

  }
}
</script>
